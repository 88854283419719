<template>
  <div
    v-if="dataLoad"
    class=" text-center my-2"
  >
    <b-card>
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </b-card>

  </div>
  <b-card v-else>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <div
        class="d-flex justify-content-end "
      >
        <b-button
          variant="primary"
          class="mb-1"
          @click="addStep()"
        >
          add Step
        </b-button>
      </div>
      <b-row
        v-for="index in stepsCount"
        :key="index"
      >
        <b-col cols="12">
          <div class="d-flex justify-content-between">
            <p class="primary--text">
              Step {{ index }}
              <feather-icon
                v-b-tooltip.hover
                class="pointer"
                title="Edit"
                icon="EditIcon"
                color="#f48a36"
                @click="openStepDialog(steps[index - 1])"
              />
            </p>
            <feather-icon
              v-b-tooltip.hover
              class="pointer"
              title="delete"
              icon="Trash2Icon"
              color="red"
              @click="deleteStep(steps[index - 1].id,index)"
            />
          </div>

        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            :name="`Title (En) ${index}`"
            rules="required"
          >
            <b-form-group
              label="Title (En)"
              label-for="name"
            >
              <b-form-input
                :id="`Title (En) ${index}`"
                v-model="steps[index - 1].title_en"

                :readonly="steps[index - 1].id!==undefined"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Title"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            :name="`Title (Ar) ${index}`"
            rules="required"
          >
            <b-form-group
              label="Title (Ar)"
              label-for="name"
            >
              <b-form-input
                :id="`Title (Ar) ${index}`"
                v-model="steps[index - 1].title_ar"

                :state="getValidationState(validationContext)"
                trim
                :readonly="steps[index - 1].id!==undefined"

                placeholder="Title"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            :name="`Description (En) ${index}`"
          >
            <b-form-group
              label="Description (En)"
              label-for="description"
            >
              <b-form-textarea
                :id="`Description (En) ${index}`"
                v-model="steps[index - 1].description_en"
                trim
                :readonly="steps[index - 1].id!==undefined"
                type="text"
                placeholder="Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            :name="`Description (Ar) ${index}`"
          >
            <b-form-group
              label="Description (Ar)"
              label-for="description"
            >
              <b-form-textarea
                :id="`Description (Ar) ${index}`"
                v-model="steps[index - 1].description_ar"
                trim
                :readonly="steps[index - 1].id!==undefined"
                type="text"
                placeholder="Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <div
        class="d-flex justify-content-end "
      >
        <b-button
          variant="primary"
          class="mb-1"
          @click="addVideo()"
        >
          add Video
        </b-button>
      </div>
      <b-row>
        <b-col
          v-for="index in videosCount"
          :key="index"
          md="3"
        >
          <!-- Name -->

          <div class="d-flex justify-content-between">
            <label>Video {{ index }}</label> <feather-icon
              v-b-tooltip.hover
              class="pointer mx-1"
              title="delete"
              icon="Trash2Icon"
              color="red"
              @click="deleteVideo(videos[index - 1].id,index)"
            />
          </div>
          <validation-provider
            #default="validationContext"
            name="Video"
            rules="required"
          >
            <b-form-group>

              <b-form-input
                id="url"
                v-model="videos[index - 1].url"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Url"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <youtube
            v-if="videos[index - 1].url"
            ref="youtube"
            width="100%"
            resize
            :video-id="getId(videos[index - 1])"
          />

        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-media>
            <b-media-aside>
              <b-col

                v-for="(image,index) in typeForm.images"
                :key="image.id"
                cols="3"
              >

                <div class="d-flex">
                  <span
                    class="deleteImgIcon"
                    @click="deleteImg(image.id , index)"
                  >
                    <feather-icon icon="XIcon" />
                  </span>
                  <b-img
                    ref="previewEl"
                    v-img
                    :src="image.url"
                    class="ml-1 typeImg"
                    height="auto"
                    width="100"
                  />

                </div>
              </b-col>

              <!--/ image -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectFile()"
              >
                Upload Images
              </b-button>
              <validation-provider
                #default="validationContext"
                name="type image"
              >
                <b-form-group>
                  <b-form-file
                    ref="refInputEl"
                    accept=".jpg, .png"
                    :hidden="true"
                    plain
                    multiple
                    :state="getValidationState(validationContext)"
                    @change="changeProfile($event) "
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>

        <b-col
          v-if="errorMsg"
          cols="12"
        >
          <p class="text-danger">
            {{ errorMsg }}
          </p>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <div>
        <b-modal
          id="editStep"
          ref="editStep"
          hide-footer
          centered
          title="Edit Step"
        >
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>

              <b-col md="6">
                <!-- Name -->
                <validation-provider
                  #default="validationContext"
                  name="Title"
                  rules="required"
                >
                  <b-form-group
                    label="Title (En)"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="stepEdit.title_en"

                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Title"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <!-- Name -->
                <validation-provider
                  #default="validationContext"
                  name="Title"
                  rules="required"
                >
                  <b-form-group
                    label="Title (Ar)"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="stepEdit.title_ar"

                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Title"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  rules="required"
                  name="Description"
                >
                  <b-form-group
                    label="Description (En)"
                    label-for="description"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="stepEdit.description_en"
                      trim
                      type="text"
                      placeholder="Description"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  rules="required"
                  name="Description"
                >
                  <b-form-group
                    label="Description (Ar)"
                    label-for="description"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="stepEdit.description_ar"
                      trim
                      type="text"
                      placeholder="Description"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row></validation-observer>

          <b-col
            cols="12"
            md="12"
          >
            <b-button
              v-if="!loaderStep"
              variant="primary"
              class="mr-1"
              @click.prevent="editStep"
            >
              Save
            </b-button>
            <b-button
              variant="outline-danger"
              class="closeModal"
              @click="closeModal()"
            >
              Cancel
            </b-button>
            <b-button
              v-if="loaderStep"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-modal>
      </div>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >  <b-button
             v-if="!loader && $route.params.id"
             variant="primary"
             class="mr-1"
             :disabled="invalid || Object.values(errors).length > 0 "
             @click="addType()"
           >
             Save Changes
           </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addType()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    FeatherIcon,
  },
  data() {
    return {
      id: this.$route.params.id,
      isVoucher: false,
      required,
      allSelected: [],
      indeterminate: false,
      countries: [],
      users: [],
      loader: false,
      loaderStep: false,
      validationErrors: {},
      errorMsg: '',
      errors: {},
      file: '',
      dataLoad: false,
      stepsCount: 1,
      videosCount: 1,

      stepEdit:
        {
          title_en: '',
          title_ar: '',
          description_ar: '',
          description_en: '',

        },

      steps: [
        {
          title_en: '',
          title_ar: '',
          description_ar: '',
          description_en: '',

        },
      ],
      videos: [
        {
          url: '',

        },
      ],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase
    const images = ref([])

    const typeForm = ref({
      images: [
      ],
    })
    return {
      getValidationState,
      typeForm,
      images,
    }
  },
  mounted() {
    this.showType()
  },
  methods: {
    getId(video) {
      return this.$youtube.getIdFromUrl(video.url)
    },
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    previewVideo() {
      const video = document.getElementById('video-preview')
      const reader = new FileReader()
      reader.readAsDataURL(this.file)
      reader.addEventListener('load', () => {
        video.src = reader.result
      })
    },
    closeModal() {
      this.$refs.editStep.hide()
    },
    addStep() {
      this.steps.push({
        title_en: '',
        title_ar: '',
        description_ar: '',
        description_en: '',

      })
      this.stepsCount += 1
    },
    addVideo() {
      this.videos.push({
        url: '',
      })
      this.videosCount += 1
    },

    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      // this.images = e.target.files[0]

      const input = e.target
      if (input.files) {
        for (let i = 0; i < input.files.length; i += 1) {
          const url = URL.createObjectURL(input.files[i])
          const img = new Image()
          img.src = window.URL.createObjectURL(input.files[i])
          img.onload = () => {
            this.typeForm.images.push({ url })
            this.images.push(input.files[i])
          }
        }

        // eslint-disable-next-line prefer-destructuring
      }
    },
    deleteImg(id, index) {
      if (id) {
        axios.get(`admin/delete_campaign_media/${id}`).then(res => {
          if (res.status === 200) {
            this.showType()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Succesfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server Error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
        }).catch(() => {
        // this.formData = new FormData()
        }).finally(() => {
          this.dataLoad = false
        })
      } else {
        this.typeForm.images.splice(index, 1)
        this.images.splice(index, 1)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Succesfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      }
    },
    deleteStep(id, index) {
      if (this.stepsCount > 1) {
        if (id) {
          axios.get(`admin/delete_step/${id}`).then(res => {
            if (res.status === 200) {
              this.showType()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Deleted Succesfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            }
          }).catch(() => {
            // this.formData = new FormData()
          }).finally(() => {
            this.loader = false
          })
        } else {
          this.steps.splice(index, 1)
          this.stepsCount -= 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'At Least one step is required',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }
    },
    deleteVideo(id, index) {
      if (id) {
        axios.get(`admin/delete_campaign_media/${id}`).then(res => {
          if (res.status === 200) {
            this.showType()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Succesfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server Error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
        }).catch(() => {
          // this.formData = new FormData()
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.videos.splice(index, 1)

        this.videosCount -= 1

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Succesfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      }
    },
    openStepDialog(step) {
      this.stepEdit = step
      this.$refs.editStep.show()
    },
    showType() {
      this.dataLoad = true

      if (this.$route.params.id) {
        axios.get(`admin/campaigns/${this.$route.params.id}`).then(res => {
          this.typeForm = res.data.data.campaign

          if (this.typeForm.steps.length > 0) {
            this.steps = this.typeForm.steps
            this.stepsCount = this.typeForm.steps.length
          } else {
            this.steps = [{
              title_en: '',
              title_ar: '',
              description_ar: '',
              description_en: '',

            }]
            this.stepsCount = 1
          }

          this.videos = this.typeForm.videos
          this.videosCount = this.typeForm.videos.length
        }).catch(() => {
          // this.formData = new FormData()
        }).finally(() => {
          this.dataLoad = false
        })
      } else {
        return false
      }
      return true
    },
    editStep() {
      this.loaderStep = true

      axios.post(`admin/step_update/${this.stepEdit.id}`, this.stepEdit).then(res => {
        if (res.status === 200) {
          this.$refs.editStep.hide()

          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          }
        }
      }).finally(() => {
        this.loaderStep = false
      })
    },
    addType() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        if (this.steps) {
          // eslint-disable-next-line no-restricted-syntax
          for (let index = 0; index < this.steps.length; index += 1) {
            if (this.steps[index].id === undefined) {
              formData.append(`steps[${index}][title_ar]`, this.steps[index].title_ar)
              formData.append(`steps[${index}][title_en]`, this.steps[index].title_en)
              formData.append(`steps[${index}][description_ar]`, this.steps[index].description_ar)
              formData.append(`steps[${index}][description_en]`, this.steps[index].description_en)
            }
          }
        }
        if (this.images) {
          // eslint-disable-next-line no-restricted-syntax
          for (const image of this.images) {
            formData.append('images[]', image)
          }
        }
        if (this.videos) {
          for (let index = 0; index < this.videos.length; index += 1) {
            if (this.videos[index].id === undefined) {
              formData.append(`videos[${index}][url]`, this.videos[index].url)
            }
          }
        }
        axios.post(`admin/campaigns/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'campaign-types' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in

        if (this.steps) {
          // eslint-disable-next-line no-restricted-syntax
          for (const step of this.steps) {
            formData.append('steps[]', step)
          }
        }
        if (this.images) {
          // eslint-disable-next-line no-restricted-syntax
          for (const image of this.images) {
            formData.append('images[]', image)
          }
        }
        if (this.videos) {
          // eslint-disable-next-line no-restricted-syntax
          for (const video of this.videos) {
            formData.append('videos[]', video)
          }
        }
        axios.post('admin/campaigns', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'campaign-types' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .deleteImgIcon{
    cursor: pointer;
    color: grey;
  }
  video{width: 200px;
  margin: 0px 10px}

  </style>
